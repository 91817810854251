import logo from './assets/header/logo.svg'
import menu from './assets/header/menu.svg'
import slogan from './assets/banner/slogan-pc.svg'
import peopleHands from './assets/banner/people-hands.png'


import courseBg1 from './assets/course/course_bg_1.png'
import courseBg2 from './assets/course/course_bg_2.png'
import courseBg3 from './assets/course/course_bg_3.png'
import courseBg4 from './assets/course/course_bg_4.png'
import courseBg5 from './assets/course/course_bg_5.png'
import courseBg6 from './assets/course/course_bg_6.png'
import courseBg7 from './assets/course/course_bg_7.png'
import courseBg8 from './assets/course/course_bg_8.png'
import courseBg9 from './assets/course/course_bg_9.png'
import courseBg10 from './assets/course/course_bg_10.png'
import courseBg11 from './assets/course/course_bg_11.png'
import courseBg12 from './assets/course/course_bg_12.png'
import courseBg13 from './assets/course/course_bg_13.png'
import courseBg14 from './assets/course/course_bg_14.png'
import courseBg15 from './assets/course/course_bg_15.png'


import inset from './assets/whychoose/inset.svg'
import whyChoose1Pc from './assets/whychoose/why-choose1-pc.png'
import whyChoose2Pc from './assets/whychoose/why-choose2-pc.png'
import whyChoose3Pc from './assets/whychoose/why-choose3-pc.png'
import whyChoose4Pc from './assets/whychoose/why-choose4-pc.png'
import whyChoose1M from './assets/whychoose/why-choose1-m.png'
import whyChoose2M from './assets/whychoose/why-choose2-m.png'
import whyChoose3M from './assets/whychoose/why-choose3-m.png'
import whyChoose4M from './assets/whychoose/why-choose4-m.png'

import hightlightIcon1 from './assets/hightlight/icon1.svg'
import hightlightIconHover1 from './assets/hightlight/icon1-hover.svg'
import hightlightIcon2 from './assets/hightlight/icon2.svg'
import hightlightIconHover2 from './assets/hightlight/icon2-hover.svg'
import hightlightIcon3 from './assets/hightlight/icon3.svg'
import hightlightIconHover3 from './assets/hightlight/icon3-hover.svg'
import hightlightTeacher from './assets/hightlight/hightlight_teacher.jpg'
import careerAdvancementPc from './assets/hightlight/career_advancement-pc.png'



import advantages1 from './assets/advantages/advantages1.jpg'
import advantages2 from './assets/advantages/advantages2.jpg'
import advantages3 from './assets/advantages/advantages3.jpg'
import advantages1Svg from './assets/advantages/advantages1.svg'
import advantages1HoverSvg from './assets/advantages/advantages1-hover.svg'
import advantages2Svg from './assets/advantages/advantages2.svg'
import advantages2HoverSvg from './assets/advantages/advantages2-hover.svg'
import advantages3Svg from './assets/advantages/advantages3.svg'
import advantages3HoverSvg from './assets/advantages/advantages3-hover.svg'

// import teacher1 from './assets/teacher/teacher1.png'
// import teacher2 from './assets/teacher/teacher2.png'
// import teacher3 from './assets/teacher/teacher3.png'
// import teacher4 from './assets/teacher/teacher4.png'

import teachers1 from './assets/teacher/teachers1.png'
import teachers2 from './assets/teacher/teachers2.png'

import company1 from './assets/aboutus/company1.jpg'
import company2 from './assets/aboutus/company2.jpg'
import company3 from './assets/aboutus/company3.jpg'
import company4 from './assets/aboutus/company4.jpg'
import company5 from './assets/aboutus/company5.jpg'
import btnLeft from './assets/aboutus/btn-left.svg'
import btnRight from './assets/aboutus/btn-right.svg'
import aboutusIcon1 from './assets/aboutus/aboutus_icon1.svg'
import aboutusIcon2 from './assets/aboutus/aboutus_icon2.svg'
import aboutusIcon3 from './assets/aboutus/aboutus_icon3.svg'
import history1 from './assets/aboutus/history1.jpg'
import history2 from './assets/aboutus/history2.jpg'
import history3 from './assets/aboutus/history3.jpg'
import history4 from './assets/aboutus/history4.jpg'
import history5 from './assets/aboutus/history5.jpg'

import honors from './assets/honors/honors.png'
import honorsM from './assets/honors/honors-m.png'
import honorsPc from './assets/honors/honors-pc.png'
import qualifications from './assets/honors/qualifications.png'
import qualificationsM from './assets/honors/qualifications-m.png'
import qualificationsPc from './assets/honors/qualifications-pc.png'

import partner1 from './assets/partners/LOGO1.png'
import partner2 from './assets/partners/LOGO2.png'
import partner3 from './assets/partners/LOGO3.png'
import partner4 from './assets/partners/LOGO4.png'
import partner5 from './assets/partners/LOGO5.png'
import partner6 from './assets/partners/LOGO6.png'
import partner7 from './assets/partners/LOGO7.png'
import partner8 from './assets/partners/LOGO8.png'
import partner9 from './assets/partners/LOGO9.png'
import partner10 from './assets/partners/LOGO10.png'
import partner11 from './assets/partners/LOGO11.png'
import partner12 from './assets/partners/LOGO12.png'
import card1 from './assets/partners/card1.png'
import card2 from './assets/partners/card2.png'
import card3 from './assets/partners/card3.png'
import card4 from './assets/partners/card4.png'
import card1M from './assets/partners/card1-m.png'
import card2M from './assets/partners/card2-m.png'
import card3M from './assets/partners/card3-m.png'
import card4M from './assets/partners/card4-m.png'

import appLogo from './assets/event/app-logo.png'
import appLogo2 from './assets/event/app-logo2.png'
import appLogo3 from './assets/event/app-logo3.png'
import appImg1 from './assets/event/app-img1.png'
import appImg2 from './assets/event/app-img2.png'
import appImg3 from './assets/event/app-img3.png'
import appImg4 from './assets/event/app-img4.png'
import appHightlight from './assets/event/app-hightlight.png'
import appHightlightM from './assets/event/app-hightlight-m.png'
import appHightlight2 from './assets/event/app-hightlight2.png'
import appHightlightM2 from './assets/event/app-hightlight2-m.png'
import appHightlight3 from './assets/event/app-hightlight3.png'
import appHightlightM3 from './assets/event/app-hightlight3-m.png'
import qrcodeApp from './assets/event/qrcode-app.png'
import qrcodeApp2 from './assets/event/qrcode-app2.png'
import qrcodeApp3 from './assets/event/qrcode-app3.png'
import qrcodeMedia from './assets/event/qrcode-media.png'

import resource1 from './assets/resources/resource1.png'
import resource2 from './assets/resources/resource2.png'
import resource3 from './assets/resources/resource3.png'
import resource4 from './assets/resources/resource4.png'

import contact from './assets/footer/contact.svg'
import redbook from './assets/footer/redbook.svg'
import wechat from './assets/footer/wechat.svg'
import tiktok from './assets/footer/tiktok.svg'
import qrcodeRedbook from './assets/footer/qrcode-redbook.png'
import qrcodeWechat from './assets/footer/qrcode-wechat.png'
import qrcodeTictok from './assets/footer/qrcode-tictok.png'

import account from './assets/login/icon_login_denglu.svg'

import ModalPc from './assets/modal/modal-pc.png'
import ModalM from './assets/modal/modal-m.png'
import CFAModalPc from './assets/modal/cfa-modal-pc.png';
import './App.css';

import CourseBoxComponent from './component/CourseBoxComponent/CourseBoxComponent';
import CourseBoxComponentV2 from './component/CourseBoxComponentV2/CourseBoxComponentV2'
import FRMCourseBoxComponent from './component/FRMCourseBoxComponent/FRMCourseBoxComponent'
import LegalCourseBoxComponent from './component/LegalCourseBoxComponent/LegalCourseBoxComponent'

import HightlightBoxComponent from './component/HightlightBoxComponent/HightlightBoxComponent';
import AdvantageBoxComponent from './component/AdvantageBoxComponent/AdvantageBoxComponent'
import TeacherBoxComponent from './component/TeacherBoxComponent/TeacherBoxComponent'
import AboutusBoxComponent from './component/AboutusBoxComponent/AboutusBoxComponent'
import HistoryComponent from './component/HistoryComponent/HistoryComponent'
import ResourceBoxComponent from './component/ResourceBoxComponent/ResourceBoxComponent'

import Login from './component/Login/Login'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Carousel, Modal, Menu, Dropdown, message } from 'antd';


import 'swiper/css';

import { useRef, useState, useEffect } from 'react';
import { storage } from "./utils/Storage";

function App() {
    const carousel = useRef(null);
    const [globalSwiper, setglobalSwiper] = useState(null);
    const [advantageImg, setAdvantageImg] = useState(advantages1);

    // const width = window.innerWidth;

    const useWidth = () => {
        const [width, setWidth] = useState(0)
        const handleResize = () => {
            console.log('resize')
            setWidth(window.innerWidth)
        }
        useEffect(() => {
            handleResize()
            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        return width
    }
    const width = useWidth();


    const [x, setX] = useState(1);

    const [route, setRoute] = useState('home');

    const hoverAdvantages = (index) => {
        const advantagesArr = [advantages1, advantages2, advantages3]
        setAdvantageImg(advantagesArr[index - 1]);
    };

    const [visible, setVisible] = useState(false);
    const setVisibleTrue = () => {
        setVisible(true);
    };

    const setVisibleFalse = () => {
        setVisible(false);
    };

    const [CFAVisible, setCFAVisible] = useState(false);
    const setCFAVisibleTrue = () => {
        setCFAVisible(true);
    };

    const setCFAVisibleFalse = () => {
        setCFAVisible(false);
    };

    const [loginVisible, setLoginVisible] = useState(false);
    const setLoginVisibleTrue = () => {
        setLoginVisible(true);
    };

    const setLoginVisibleFalse = () => {
        setLoginVisible(false);
    };


    const [downloadIsHovered, setDownloadIsHovered] = useState(false);
    const [historyIsHovered, setHistoryIsHovered] = useState(false);
    const [download2IsHovered, setDownload2IsHovered] = useState(false);
    const [download3IsHovered, setDownload3IsHovered] = useState(false);

    const handleMouseEnterDownload = () => {
        setDownloadIsHovered(true);
    };

    const handleMouseLeaveDownload = () => {
        setDownloadIsHovered(false);
    };

    const handleMouseEnterDownload2 = () => {
        setDownload2IsHovered(true);
    };

    const handleMouseLeaveDownload2 = () => {
        setDownload2IsHovered(false);
    };
    const handleMouseEnterDownload3 = () => {
        setDownload3IsHovered(true);
    };

    const handleMouseLeaveDownload3 = () => {
        setDownload3IsHovered(false);
    };
    const handleMouseEnterHistory = () => {
        setHistoryIsHovered(true);
    };

    const handleMouseLeaveHistory = () => {
        setHistoryIsHovered(false);
    };

    const getAvator = () => {
        const user = storage.get('CURRENT-USER');
        if (user) {
            return user.avatar
        } else {
            return undefined
        }
    }

    const logout = () => {
        storage.remove('ACCESS-TOKEN');
        storage.remove('CURRENT-USER');
        window.location.reload();
    }

    const [contactIsHovered, setContactIsHovered] = useState(false);
    const [wechatIsHovered, setWechatIsHovered] = useState(false);
    const [redbookIsHovered, setRedbookIsHovered] = useState(false);
    const [tiktokIsHovered, setTictokIsHovered] = useState(false);

    const [avatorIsHovered, setAvatorIsHovered] = useState(false);


    const handleMouseEnterContack = () => {
        setContactIsHovered(true);
    };

    const handleMouseLeaveContack = () => {
        setContactIsHovered(false);
    };
    const handleMouseEnterWechat = () => {
        setWechatIsHovered(true);
    };

    const handleMouseLeaveWechat = () => {
        setWechatIsHovered(false);
    };
    const handleMouseEnterRedbook = () => {
        setRedbookIsHovered(true);
    };

    const handleMouseLeaveRedbook = () => {
        setRedbookIsHovered(false);
    };
    const handleMouseEnterTictok = () => {
        setTictokIsHovered(true);
    };

    const handleMouseLeaveTictok = () => {
        setTictokIsHovered(false);
    };

    const handleMouseEnterAvator = () => {
        setAvatorIsHovered(true);
    };

    const handleMouseLeaveAvator = () => {
        setAvatorIsHovered(false);
    };

    const avator = getAvator();
    useEffect(() => {
        // 组件挂载完成时的逻辑
        console.log(`Component mounted with props:`);
        const intervalId = setInterval(() => {
            setX(x => x + 1);
        }, 5000);

        // 清理函数
        return () => {
            console.log(`Component will unmount`);
            clearInterval(intervalId)
        };
    }, []);



    const menuComponent = (
        <Menu>
            <Menu.Item>
                <a href='https://lanxingkaoshi.com/legal-exam' className='head-title'>法考</a>
            </Menu.Item>
            <Menu.Item>
                <a href='#course' className='head-title'>课程介绍</a>
            </Menu.Item>
            <Menu.Item>
                <a href='https://lanxingkaoshi.com/tiku' className='head-title'>题库模考</a>
            </Menu.Item>
            <Menu.Item>
                <a href='https://lanxingkaoshi.com/h5/cpaTest' className='head-title'>全真模拟</a>
            </Menu.Item>
            <Menu.Item>
                <a href='#reason' className='head-title'>选择理由</a>
            </Menu.Item>
            <Menu.Item>
                <a href='#teacher' className='head-title'>名师介绍</a>
            </Menu.Item>
            <Menu.Item>
                <a href='#about' className='head-title'>关于我们</a>
            </Menu.Item>
            <Menu.Item>
                <a href='#cfaapp' className='head-title'>揽星 CFA</a>
            </Menu.Item>
            <Menu.Item>
                <a href='#ourapp' className='head-title first-head-title'>揽星会计App</a>
            </Menu.Item>
        </Menu>
    )

    return (
        <div className="App">
            <div className='bgFFF'>
                <div className='header-box'>
                    <header className="App-header" id='home'>
                        <img id='logo' src={logo} alt='logo' />
                        {
                            avatorIsHovered && <div className='logout' onClick={() => {
                                logout()
                            }} onMouseLeave={handleMouseLeaveAvator} onMouseEnter={handleMouseEnterAvator}>
                                退出登录
                            </div>
                        }
                        <div className='title-box'>
                            <div className='title-btn-box'>
                                <a href='https://lanxingkaoshi.com/legal-exam' className='head-title'>法考</a>
                                <a href='#course' className='head-title first-head-title'>课程介绍</a>
                                <a href='https://www.lanxingkaoshi.com/tiku' className='head-title'>题库模考</a>
                                <a href='https://lanxingkaoshi.com/h5/cpaTest' className='head-title'>全真模拟</a>
                                <a href='#reason' className='head-title'>选择理由</a>
                                <a href='#teacher' className='head-title'>名师介绍</a>
                                <a href='#about' className='head-title'>关于我们</a>
                                <a href='#cfaapp' className='head-title'>揽星 CFA</a>
                                <a href='#frmapp' className='head-title first-head-title'>揽星 FRM</a>
                                <a href='#ourapp' className='head-title first-head-title'>揽星会计App</a>
                            </div>
                            {
                                avator && <div style={{
                                    marginLeft: '20px'
                                }} onMouseLeave={handleMouseLeaveAvator} onMouseEnter={handleMouseEnterAvator}>
                                    <img style={{
                                        width: '30px',
                                        height: '30px'
                                    }} src={avator} alt='avator' />
                                </div>
                            }
                            {
                                !avator && <div className='login-btn-box'>
                                    <div className='login-btn' onClick={() => {
                                        setLoginVisibleTrue()
                                    }}>
                                        <div><img id='logo' src={account} alt='logo' /></div>
                                        <div>登录/注册</div>
                                    </div>
                                </div>
                            }

                        </div>

                        <div className='mobile-login'>
                            {avator && <div style={{
                                marginRight: '10px'
                            }} onMouseEnter={handleMouseEnterAvator}
                                onMouseLeave={handleMouseLeaveAvator}>
                                <img className='menu' src={avator} alt='avator' />
                            </div>}
                            {!avator && <div className='login-btn-box'>
                                <div className='login-btn' onClick={() => {
                                    setLoginVisibleTrue()
                                }}>
                                    <div><img id='logo' src={account} alt='logo' /></div>
                                    <div>登录/注册</div>
                                </div>
                            </div>}
                            <Dropdown overlay={menuComponent}>
                                <img className='menu' src={menu} alt='menu' />
                            </Dropdown>
                        </div>


                    </header>
                </div>
            </div>


            {
                route === 'home' && <div>
                    <div className="top-box">
                        <div className="container">
                            <div className="left-box">
                                <img src={slogan} alt="揽星财会一次过" />
                                <p className="banner-text">
                                    <span>课程:</span>
                                    双师双体系，私教督学，多重通关保障；
                                    <span>资料:</span>
                                    重点精炼，考前急救，高效通关；
                                    <span>app:</span>
                                    一站式备考真题练习平台
                                </p>
                                <button
                                    className="slogan-btn"
                                    onClick={() => {
                                        setVisibleTrue();
                                    }}
                                >
                                    了解课程
                                </button>
                            </div>
                            <div className='right-box'>
                                <img src={peopleHands} alt='' />
                            </div>
                        </div>
                    </div>

                    <div id='course' className='container bgFFA516'>
                        <div className='title'>会计课程介绍</div>
                        <div className='subtitle'>CPA COURSE INTRODUCTION</div>
                        <div className='courses'>
                            <CourseBoxComponent
                                firstTitle="高效"
                                secondTitle="畅听班"
                                firstSubtitle="1年私人辅导无限次使用"
                                secondSubtitle="录播模式自由观看"
                                thirdSubtitle="6980元"
                                img={courseBg1}
                                clickBtn={setVisibleTrue}
                            ></CourseBoxComponent>
                            <CourseBoxComponent
                                firstTitle="高效精品班"
                                secondTitle="系列"
                                firstSubtitle="2年私人辅导无限次使用，五年课程有效期"
                                secondSubtitle="就业指导，资源共享，录播+直播双模式"
                                thirdSubtitle="9880元"
                                img={courseBg2}
                                clickBtn={setVisibleTrue}
                            ></CourseBoxComponent>
                            <CourseBoxComponent
                                firstTitle="VIP"
                                secondTitle="菁英计划班"
                                firstSubtitle="私教1V1点播课程，5个考季后哪科不过退哪科"
                                secondSubtitle=""
                                thirdSubtitle="18800元"
                                img={courseBg3}
                                clickBtn={setVisibleTrue}
                            ></CourseBoxComponent>
                            <CourseBoxComponent
                                firstTitle="高效"
                                secondTitle="挑战班"
                                firstSubtitle="两年内所有科目通过"
                                secondSubtitle="即可享受70%退费"
                                thirdSubtitle="15800元"
                                img={courseBg4}
                                clickBtn={setVisibleTrue}
                            ></CourseBoxComponent>
                            {width <= 820 ? <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '2.67vw'
                            }}>
                                <CourseBoxComponent
                                    firstTitle="中级高效"
                                    secondTitle="畅听班"
                                    firstSubtitle="录播模式自由观看，附赠考前急救直播课"
                                    secondSubtitle="线上+班群内在线答疑"
                                    thirdSubtitle="1800元"
                                    img={courseBg5}
                                    clickBtn={setVisibleTrue}
                                ></CourseBoxComponent>
                                <CourseBoxComponent
                                    firstTitle="中级精英"
                                    secondTitle="协议班"
                                    firstSubtitle="直播录播双模式，真人辅导急速答疑"
                                    secondSubtitle="2年不过退费或免费重修"
                                    thirdSubtitle="6680元"
                                    img={courseBg6}
                                    clickBtn={setVisibleTrue}
                                ></CourseBoxComponent>
                            </div> : <div style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                gap: '20px'
                            }}>
                                <CourseBoxComponentV2
                                    firstTitle="中级高效"
                                    secondTitle="畅听班"
                                    firstSubtitle="录播模式自由观看，附赠考前急救直播课"
                                    secondSubtitle="线上+班群内在线答疑"
                                    thirdSubtitle="1800元"
                                    img={courseBg5}
                                    clickBtn={setVisibleTrue}
                                ></CourseBoxComponentV2>
                                <CourseBoxComponentV2
                                    firstTitle="中级精英"
                                    secondTitle="协议班"
                                    firstSubtitle="直播录播双模式，真人辅导急速答疑"
                                    secondSubtitle="2年不过退费或免费重修"
                                    thirdSubtitle="6680元"
                                    img={courseBg6}
                                    clickBtn={setVisibleTrue}
                                ></CourseBoxComponentV2>
                            </div>
                            }
                        </div>
                    </div>

                    <div id='CFAcourse' className='container bgFFFFFF mt100'>
                        <div className='title'>CFA课程介绍</div>
                        <div className='subtitle'>CFA COURSE INTRODUCTION</div>
                        <div className='courses cfacourses'>
                            <CourseBoxComponent
                                isCFA='true'
                                firstTitle="无忧"
                                secondTitle="协议班"
                                firstSubtitle="考完一级送二级"
                                secondSubtitle="一级未通过可免费续学一期或退费80%"
                                thirdSubtitle="10800元"
                                img={courseBg7}
                                clickBtn={setCFAVisibleTrue}
                            ></CourseBoxComponent>
                            <CourseBoxComponent
                                isCFA='true'
                                firstTitle="畅优"
                                secondTitle="奖学金班"
                                firstSubtitle="一次性通过3个级别，奖励15000元（累计）"
                                secondSubtitle="学籍5年，5年未通过，哪个级别不过退哪个"
                                thirdSubtitle="28800元"
                                img={courseBg8}
                                clickBtn={setCFAVisibleTrue}
                            ></CourseBoxComponent>
                            <CourseBoxComponent
                                isCFA='true'
                                firstTitle="精品"
                                secondTitle="畅学班"
                                firstSubtitle="3年内未考完"
                                secondSubtitle="免费续学1年"
                                thirdSubtitle="18800元"
                                img={courseBg9}
                                clickBtn={setCFAVisibleTrue}
                            ></CourseBoxComponent>
                            <CourseBoxComponent
                                isCFA='true'
                                firstTitle="CFA | FRM"
                                secondTitle="双证奖学金班"
                                firstSubtitle="CFA一次性通过3个级别，奖励15000元（累计）"
                                secondSubtitle="FRM一次性通过2个级别，最高奖励7000元（累计）"
                                fourthSubtitle="学籍6年，6年不过哪个级别退哪个"
                                thirdSubtitle="35800元"
                                img={courseBg10}
                                clickBtn={setCFAVisibleTrue}
                            ></CourseBoxComponent>
                        </div>
                    </div>


                    <div className='container bgFFFFFF mt100'>
                        <div className='title'>FRM课程介绍</div>
                        <div className='subtitle'>FRM COURSE INTRODUCTION</div>
                        <div className='courses cfacourses'>
                            <FRMCourseBoxComponent
                                isCFA='true'
                                firstTitle="FRM"
                                secondTitle="一级"
                                firstSubtitle="1V1专业辅导老师全程督导带学"
                                secondSubtitle="1h极速响应，答疑迅速"
                                thirdSubtitle="8800元"
                                img={courseBg11}
                                clickBtn={setCFAVisibleTrue}
                            ></FRMCourseBoxComponent>
                            <FRMCourseBoxComponent
                                isCFA='true'
                                firstTitle="FRM"
                                secondTitle="畅优奖学金班"
                                firstSubtitle="一次性通过2个级别，最高奖励7000元（累计）"
                                secondSubtitle="学籍3年，3年未通过，哪个级别不过退哪个"
                                thirdSubtitle="15800元"
                                img={courseBg12}
                                clickBtn={setCFAVisibleTrue}
                            ></FRMCourseBoxComponent>
                            <FRMCourseBoxComponent
                                isCFA='true'
                                firstTitle="CFA | FRM"
                                secondTitle="双证奖学金班"
                                firstSubtitle="CFA一次性通过3个级别，奖励15000元（累计）"
                                secondSubtitle="FRM一次性通过2个级别，最高奖励7000元（累计）"
                                fourthSubtitle="学籍6年，6年不过哪个级别退哪个"
                                thirdSubtitle="35800元"
                                img={courseBg10}
                                clickBtn={setCFAVisibleTrue}
                            ></FRMCourseBoxComponent>
                        </div>
                    </div>

                    <div className='container bgFFFFFF mt100'>
                        <div className='title'>法考课程介绍</div>
                        <div className='subtitle'>FRM COURSE INTRODUCTION</div>
                        <div className='courses cfacourses'>
                            <LegalCourseBoxComponent
                                isCFA='true'
                                firstTitle="考霸魔方"
                                secondTitle="冲刺全程班"
                                firstSubtitle="3V1升级服务，24小时答疑"
                                secondSubtitle="六轮高效学习、高频督学，九轮测试模考"
                                thirdSubtitle="12800元"
                                img={courseBg13}
                                clickBtn={setCFAVisibleTrue}
                            ></LegalCourseBoxComponent>
                            <LegalCourseBoxComponent
                                isCFA='true'
                                firstTitle="挑战"
                                secondTitle="全程班"
                                firstSubtitle="3V1升级服务，24小时答疑"
                                secondSubtitle="六轮高效学习、高频督学，九轮测试模考"
                                fourthSubtitle="1v1视频讲解、互动直播教学"
                                thirdSubtitle="14800元"
                                img={courseBg14}
                                clickBtn={setCFAVisibleTrue}
                            ></LegalCourseBoxComponent>
                            <LegalCourseBoxComponent
                                isCFA='true'
                                firstTitle="1v1"
                                secondTitle="保过班"
                                firstSubtitle="3V1升级服务，24小时答疑"
                                secondSubtitle="六轮高效学习、高频督学，九轮测试模考"
                                fourthSubtitle="1v1视频讲解+电话答疑、互动直播教学"
                                thirdSubtitle="29990元"
                                img={courseBg15}
                                clickBtn={setCFAVisibleTrue}
                            ></LegalCourseBoxComponent>
                        </div>
                    </div>
                    <div id='reason' className='container mt100 bg14161E relative'>
                        <div className='title'>为什么选择揽星？</div>
                        <div className='subtitle'>WHY CHOOSE US</div>
                        <div className='child_title'>专注财会教学</div>
                        <div className='child_subtitle'>Focus on accounting teaching</div>
                        <img className='inset-img' src={inset} alt='inset' />
                        <div className='reasons pc'>
                            <div><img src={whyChoose1Pc} alt='高效课程 全直播/全套精品视频课 两套课程模式随心选' /></div>
                            <div><img src={whyChoose2Pc} alt='超强师资 一年过六科 教研团' /></div>
                            <div><img src={whyChoose3Pc} alt='精细化辅导 真人服务，无限答疑，考前带背 线上督学自习室…' /></div>
                            <div><img src={whyChoose4Pc} alt='就业无忧 真人服务，无限答疑，考前带背 线上督学自习室…' /></div>
                        </div>
                        <div className='reasons mobile'>
                            <div><img src={whyChoose1M} alt='高效课程 全直播/全套精品视频课 两套课程模式随心选' /></div>
                            <div><img src={whyChoose2M} alt='超强师资 一年过六科 教研团' /></div>
                            <div><img src={whyChoose3M} alt='精细化辅导 真人服务，无限答疑，考前带背 线上督学自习室…' /></div>
                            <div><img src={whyChoose4M} alt='就业无忧 真人服务，无限答疑，考前带背 线上督学自习室…' /></div>
                        </div>
                    </div>

                    <div className='container mt100 bgFFFFFF'>
                        <div className='title'>课程亮点</div>
                        <div className='subtitle'>COURSE HIGHTLIGHTS</div>
                        <div className='courses mt60'>
                            <HightlightBoxComponent
                                title='专业课程'
                                subtitle='因材施教，深度讲解'
                                img={hightlightIcon1}
                                hoverimg={hightlightIconHover1}
                                content='1、直播录播 bold双体系bold ，双重课程覆盖enter2、定制化的CPA课程设计，满足不同学员需求，针对 bold不同基础bold 的学员提供相匹配的系统课程，为学员提供全面、系统的CPA备考方案，帮助大家轻松通过考试并取得CPA证书！'

                            />
                            <HightlightBoxComponent
                                title='沉浸学习'
                                subtitle='激发兴趣，学练结合'
                                img={hightlightIcon2}
                                hoverimg={hightlightIconHover2}
                                content='1、直播带来沉浸式学习体验，跟着老师走，通关不用愁enter2、课后及时辅导，拯救你的不认真enter3、bold独家CPA备考法宝bold，过关技巧快速GET'
                            />
                            <HightlightBoxComponent
                                title='用心服务'
                                subtitle='服务至上 学员为主'
                                img={hightlightIcon3}
                                hoverimg={hightlightIconHover3}
                                content='1、就业指导，资源共享enter2、终身专人服务：真人教学辅导答疑，有效期2-5年，高效助力学员通关enter3、挑战自我上限：零基础参与挑战班，2年服务期限内通过全科目退费'
                            />
                        </div>
                        <div className='courses mt20'>
                            <div className='hightlight_box left_box' style={{
                                backgroundImage: `url(${hightlightTeacher})`,
                                backgroundSize: '100% 100%'
                            }}>
                                <div className='title'>一流名师</div>
                                <div className='subtitle'>专业师资团队授课</div>
                            </div>
                            <div className='hightlight_box relative right_box'>
                                <div className='title'>职业进阶全覆盖</div>
                                <div className='subtitle'>从CPA小白进阶到职场CPA达人！</div>
                                <img src={careerAdvancementPc} alt='career advancement pc' />
                            </div>
                        </div>
                    </div>

                    <div id='teacher' className='container mt100 bg14161E teachers'>
                        <div className='title'>
                            <span>专业师资队伍</span>
                            {/* <span className='FFA516'>过六科</span>
                    <span> 教研天团</span> */}
                        </div>
                        <div className='subtitle'>Professional Teaching Staff</div>
                        <div className='content mt40'>
                            <span>所有老师人均名校毕业，教龄 </span>
                            <span className='FFA516'>7年以上</span>
                            <span> ，具备扎实的财税知识，近 </span>
                            <span className='FFA516'>10年</span>
                            <span> 财会教学经验，累计实战教学培训 </span>
                            <span className='FFA516'>10000+h</span>
                            <span> ，教学经验丰富，授课幽默，已由 </span>
                            <span className='FFA516'>10000+</span>
                            <span> 名学员验证，能在最快时间内提分，“保姆”陪伴式辅导。</span>
                        </div>
                        <div className='teachers_box'>
                            <div className='teachers-row' style={{
                                backgroundImage: `url(${teachers1})`,
                                backgroundPositionX: `${x * 300}px`
                            }}></div>
                            <div className='teachers-row' style={{
                                backgroundImage: `url(${teachers2})`,
                                backgroundPositionX: `-${x * 300}px`
                            }}></div>
                            {/* <TeacherBoxComponent
                        title='李开源老师'
                        subtitle1='武汉大学金融硕士'
                        subtitle2='金融讲师'
                        content='常年授教证券从业、银行从业以及CPA等课程，自主研究口诀式记忆法，使其短时间掌握考点'
                        img={teacher1} />
                    <TeacherBoxComponent
                        title='杨雪老师'
                        subtitle1='会计学硕士'
                        subtitle2='中级会计师'
                        content='“接地气式”教学法将晦涩考点用生活中的例子通俗表达，受到学员追捧，是高通过率和90分高分的产出者'
                        img={teacher2} />
                    <TeacherBoxComponent
                        title='晓峰老师'
                        subtitle1='暨南大学博士'
                        subtitle2='中国注会师/税务师/会计师'
                        content='乐于分享所学所思，授课经营丰富。曾在高校担任讲师，教学方式风趣幽默，浅显易懂，深受广大学员喜爱'
                        img={teacher3} />
                    <TeacherBoxComponent
                        title='田广锟老师'
                        subtitle1='耶鲁大学MBA'
                        subtitle2='财会金融类教学研究10年+'
                        content='理论功底深厚，授课幽默，说人话，富有表现力，考点把握精准，独创“吃货”学习法，受到广大学员的好评'
                        img={teacher4} /> */}
                        </div>
                    </div>

                    <div className='advantages_box mt100 bgFFFFFF'>
                        <div className='advantages'>
                            <div className='left_box'>
                                <div>
                                    <div className='title'>教学优势</div>
                                    <div className='subtitle'>TEACHING ADVANTAGES</div>
                                </div>
                                <div className='pd40' style={{
                                    marginTop: '10px'
                                }}>
                                    <AdvantageBoxComponent
                                        title='专属辅导老师陪伴'
                                        subtitle='全考季，日日督学，向懒惰说拜拜~'
                                        img={advantages1Svg}
                                        hoverimg={advantages1HoverSvg}
                                        hover={hoverAdvantages} />
                                    <AdvantageBoxComponent
                                        title='七大课程体系'
                                        subtitle='从入门到押题，形成学习闭环，全方位夯实基础~'
                                        img={advantages2Svg}
                                        hoverimg={advantages2HoverSvg}
                                        hover={hoverAdvantages} />
                                    <AdvantageBoxComponent
                                        title='独家解题技巧'
                                        subtitle='举一反三，成就解题好方法~不仅如此'
                                        img={advantages3Svg}
                                        hoverimg={advantages3HoverSvg}
                                        hover={hoverAdvantages} />
                                </div>
                            </div>
                            <div className='right_box'>
                                <img src={advantageImg} alt='' />
                            </div>
                        </div>
                    </div>



                    <div id='about' className='container mt100 bgFFFFFF'>
                        <div className='title'>关于我们</div>
                        <div className='subtitle'>ABOUT US</div>
                        <div className='banner_box bgFFA516 mt60 relative'>
                            <div className='left_content'>
                                <div className='title'>做年轻人喜爱的</div>
                                <div className='title'>财经课程</div>
                                <div className='subtitle'>
                                    湖南揽星教育咨询有限公司，成立于 2018 年，专注会计实战教学，是一家服务于中国投身于财经、会计从业人员的专业教育机构。 揽星教育秉承着“用心为学生服务、做年轻人喜欢的课”的教育理念，致力于为个人解决缺乏渠道和职业规划等困惑，帮助企业高效、系统提升财务运作问题。
                                </div>
                            </div>
                            <div className='right_content'>
                                <Carousel ref={carousel} effect="fade">
                                    <div className='content-img-wrap'>
                                        <img src={company1} alt=''></img>
                                    </div>
                                    <div className='content-img-wrap'>
                                        <img src={company2} alt=''></img>
                                    </div>
                                    <div className='content-img-wrap'>
                                        <img src={company3} alt=''></img>
                                    </div>
                                    <div className='content-img-wrap'>
                                        <img src={company4} alt=''></img>
                                    </div>
                                    <div className='content-img-wrap'>
                                        <img src={company5} alt=''></img>
                                    </div>
                                </Carousel>
                                <img onClick={() => {
                                    carousel.current.prev();
                                }} className='btn-left' src={btnLeft} alt='' />
                                <img onClick={() => {
                                    carousel.current.next();
                                }} className='btn-right' src={btnRight} alt='' />
                            </div>
                        </div>
                        <div className='aboutus_box'>
                            <AboutusBoxComponent
                                title='企业使命'
                                subtitle1='用心为学生服务，'
                                subtitle2='做年轻人喜欢的课程'
                                img={aboutusIcon1}
                            />
                            <AboutusBoxComponent
                                title='企业愿景'
                                subtitle1='致力成为财会教育领域领军品牌'
                                subtitle2='帮助更多学员在财会考证路上轻松过！'
                                img={aboutusIcon2}
                            />
                            <AboutusBoxComponent
                                title='企业价值观'
                                subtitle1='品质教学、成就学员、用心服务、'
                                subtitle2='坚持创新、共赢未来'
                                img={aboutusIcon3}
                            />
                        </div>
                        <div className='title_box'>
                            <div className='little_title'>发展历程</div>
                            <div className='little_subtitle'>2018-2023</div>
                            <div className='btn_box'>
                                <img src={btnLeft} alt='' onClick={() => {
                                    globalSwiper.slidePrev();
                                }}></img>
                                <img src={btnRight} alt='' onClick={() => {
                                    globalSwiper.slideNext()
                                }}></img>
                            </div>
                        </div>
                        <div className='history_box'>
                            <Swiper
                                id='mySwiper'
                                spaceBetween={20}
                                slidesPerView={width !== 0 && width > 820 ? 3.5 : 1.2}
                                onSwiper={(swiper) => {
                                    setglobalSwiper(swiper);
                                }}
                            >
                                <SwiperSlide>
                                    <HistoryComponent title='2023年4月' subtitle='推出揽星会计APP。APP中囊括了包括在线学习，教学视频、答疑解惑、模拟考试、AI解答、学习记录以及学习提醒等功能；CPA题库，强化练习，注会六科全覆盖，重点难点随时看。' img={history5} />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <HistoryComponent title='2023年' subtitle='财会行业分享会是由揽星教育主办的，每个月会邀请到一位财会行业的大咖、CPA注册会计师持证人给大家分享CPA备考经验以及财会行业的职业经历、行业前沿信息以及职业规划等干货知识分享。' img={history1} />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <HistoryComponent title='2022年' subtitle='汲取过往的会计教学与线上教学的经验，打开了新的思路，推出了畅听班、精品班、协议班等各类线上专业班型，方便各类不同需求考生，帮助学员难点突破，重点突围。 ' img={history2} />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <HistoryComponent title='2019年' subtitle='转型线上培训交流，打磨课程内容，提供课后督学服务。' img={history3} />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <HistoryComponent title='2018年' subtitle='湖南揽星教育咨询有限公司董事长于长沙建立揽星教育品牌' img={history4} />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>

                    <div className="container mt100 bg14161E relative">
                        <div className="title">荣誉资质</div>
                        <div className="subtitle">HONORS & QUALIFICATIONS</div>
                        <div className="honors_box">
                            <div className="honorsPic">
                                <img src={width !== 0 && width > 820 ? honorsPc : honorsM} alt="" />
                            </div>
                            <div className='honors' style={{
                                backgroundImage: `url(${honors})`,
                                backgroundPositionX: `-${x * 300}px`
                            }}>
                            </div>
                        </div>
                        <div className="honors_box">
                            <div className="honorsPic">
                                <img
                                    src={width !== 0 && width > 820 ? qualificationsPc : qualificationsM}
                                    alt=""
                                />
                            </div>
                            <div className='honors' style={{
                                backgroundImage: `url(${qualifications})`,
                                backgroundPositionX: `-${x * 100}px`
                            }}>
                            </div>
                        </div>
                    </div>

                    <div className='container mt100 bgFFFFFF'>
                        <div id='ourapp' className='title'>品牌大事件</div>
                        <div className='subtitle'>BRAND EVENT</div>
                        <div className='bg141623 appbox mt50'>
                            <div className='left_box'>
                                <div className='mt40'>
                                    <div><img src={appLogo} alt='' /></div>
                                    <div className='title_box'>
                                        <div className='title'>揽星会计App</div>
                                        {/* <div className='subtitle'>首个免费财会学习App</div> */}
                                        <div className='content'>
                                            APP中囊括了包括在线学习，教学视频、答疑解惑、模拟考试、AI解答、学习记录以及学习提醒等功能，科技赋能，旨在帮助广大学生能够更简便的完成学业
                                        </div>
                                    </div>
                                </div>
                                <div className="app-hightlight mt50">
                                    <img src={width !== 0 && width > 820 ? appHightlight : appHightlightM} alt="" />
                                </div>
                                <div className='event-btn mt70 relative'
                                    onMouseEnter={handleMouseEnterDownload}
                                    onMouseLeave={handleMouseLeaveDownload}>
                                    立即下载
                                    <img className='event-btn-qrcode' src={qrcodeApp} alt='' style={{
                                        display: downloadIsHovered ? 'block' : 'none'
                                    }} />
                                </div>

                            </div>
                            <div className='right_box'>
                                <img src={appImg1} alt='' />
                            </div>
                        </div>

                        <div id='cfaapp' className='bg141623 appbox mt50'>
                            <div className='left_box'>
                                <div className='mt40'>
                                    <div><img src={appLogo2} alt='' /></div>
                                    <div className='title_box'>
                                        <div className='title'>揽星CFA App</div>
                                        <div className='subtitle'>免费财会学习App</div>
                                        <div className='content'>
                                            APP中囊括了一、二、三级CFA题库、教学视频、学习资料PDF、做题记录、错题本等用来提升CFA能力一系列功能，旨在帮助广大CFA备考的同学能够更轻松的拿到证书
                                        </div>
                                    </div>
                                </div>
                                <div className="app-hightlight mt50">
                                    <img src={width !== 0 && width > 820 ? appHightlight2 : appHightlightM2} alt="" />
                                </div>
                                <div className='event-btn mt70 relative'
                                    onMouseEnter={handleMouseEnterDownload2}
                                    onMouseLeave={handleMouseLeaveDownload2}
                                    onClick={() => {
                                        window.open('https://sj.qq.com/appdetail/com.lanxingkaoshi.cfa');
                                    }}>
                                    立即下载
                                    <img className='event-btn-qrcode' src={qrcodeApp2} alt='' style={{
                                        display: download2IsHovered ? 'block' : 'none'
                                    }} />
                                </div>

                            </div>
                            <div className='right_box'>
                                <img src={appImg3} alt='' />
                            </div>
                        </div>

                        <div id='frmapp' className='bg141623 frmapp appbox mt50'>
                            <div className='left_box'>
                                <div className='mt40'>
                                    <div><img src={appLogo3} alt='' /></div>
                                    <div className='title_box'>
                                        <div className='title'>揽星FRM App</div>
                                        <div className='subtitle'>免费金融学习App</div>
                                        <div className='content'>
                                            金融⻛险管理师备考工具，海量高质量题库免费任你刷，一、二级全覆盖，包含重点难点和考点，随时随地进行刷题和练习；了解FRM考试，备考各个科目；涵盖注会FRM官方教材、学习建议书、主观题常考章节考点等多种学习资料
                                        </div>
                                    </div>
                                </div>
                                <div className="app-hightlight mt50">
                                    <img src={width !== 0 && width > 820 ? appHightlight3 : appHightlightM3} alt="" />
                                </div>
                                <div className='event-btn mt70 relative'
                                    onMouseEnter={handleMouseEnterDownload3}
                                    onMouseLeave={handleMouseLeaveDownload3}
                                    // TODO: 待补充下载地址
                                    onClick={() => {
                                        // window.open('https://sj.qq.com/appdetail/com.lanxingkaoshi.cfa');
                                    }}>
                                    立即下载
                                    <img className='event-btn-qrcode' src={qrcodeApp3} alt='' style={{
                                        display: download3IsHovered ? 'block' : 'none'
                                    }} />
                                </div>

                            </div>
                            <div className='right_box'>
                                <img src={appImg4} alt='' />
                            </div>
                        </div>

                        <div className='bg005 appbox mt50'>
                            <div className='left_box'>
                                <div className='title_box'>
                                    <div className='title'>财会行业分享会</div>
                                    <div className='subtitle'>由揽星教育主办的</div>
                                    <div className='content'>
                                        每个月会邀请到一位财会行业的大咖、CPA注册会计师持证人给大家分享CPA备考经验以及财会行业的职业经历、行业前沿信息以及职业规划等干货知识分享，向财会行业从业、学生提供一个直观的专业分享交流平台，帮助其在财会考证路上轻松过
                                    </div>
                                </div>
                                <div className='event-btn mt50 relative'
                                    onMouseEnter={handleMouseEnterHistory}
                                    onMouseLeave={handleMouseLeaveHistory}>
                                    查看往期
                                    <img className='event-btn-qrcode' src={qrcodeMedia} alt='' style={{
                                        display: historyIsHovered ? 'block' : 'none'
                                    }} />
                                </div>
                            </div>
                            <div className='right_box'>
                                <img src={appImg2} alt='' />
                            </div>
                        </div>

                    </div>

                    <div className='container mt100 partners'>
                        <div className='title'>媒体报道/合作伙伴</div>
                        <div className='subtitle'>MEDIA COVERAGE & PARTNERS</div>
                        <div className='partners_box'>
                            <img src={partner7} alt='' />
                            <img src={partner8} alt='' />
                            <img src={partner9} alt='' />
                            <img src={partner10} alt='' />
                            <img src={partner11} alt='' />
                            <img src={partner12} alt='' />
                        </div>
                        <div className='card_box'>
                            <img src={width !== 0 && width > 820 ? card1 : card1M} alt=''
                            // onClick={() => {
                            //     window.open('https://page.om.qq.com/page/OckIBGoKkZyJ2RH3IKdtsxmw0')
                            // }}
                            />
                            <img src={width !== 0 && width > 820 ? card2 : card2M} alt=''
                            // onClick={() => {
                            //     window.open('https://baby.ifeng.com/c/8JyGSVLLDiK')
                            // }}
                            />
                            <img src={width !== 0 && width > 820 ? card3 : card3M} alt=''
                            // onClick={() => {
                            //     window.open('https://www.sohu.com/na/654139457_121622050')
                            // }}
                            />
                            <img src={width !== 0 && width > 820 ? card4 : card4M} alt=''
                            // onClick={() => {
                            //     window.open("https://www.toutiao.com/article/7239698508746408460");
                            // }}
                            />
                        </div>
                    </div>


                    <div className='container bgFFA516 mt100'>
                        <div className='title'>免费领取考证资料</div>
                        <div className='subtitle'>FREE RESOURCES</div>
                        <div className='resources'>
                            <ResourceBoxComponent title='6科三色笔记' img={resource1} btnClick={setVisibleTrue} />
                            <ResourceBoxComponent title='历年真题' img={resource2} btnClick={setVisibleTrue} />
                            <ResourceBoxComponent title='通关宝典' img={resource3} btnClick={setVisibleTrue} />
                            <ResourceBoxComponent title='重难点集锦' img={resource4} btnClick={setVisibleTrue} />
                        </div>
                        <div className='btns'>
                            <div className='download_btn' onClick={() => {
                                setVisibleTrue()
                            }}>逐个下载</div>
                            <div className='download_btn' onClick={() => {
                                setVisibleTrue()
                            }}>网盘打包下载</div>
                        </div>
                    </div>


                    <div className='foot'>
                        <div className='btns'>
                            <div
                                onMouseEnter={handleMouseEnterContack}
                                onMouseLeave={handleMouseLeaveContack}
                                className='relative'>
                                <img src={contact} alt='' />
                            </div>
                            <div
                                onMouseEnter={handleMouseEnterWechat}
                                onMouseLeave={handleMouseLeaveWechat}
                                className='relative'>
                                <img className='foot_qrcode' src={qrcodeWechat} alt='' style={{
                                    display: wechatIsHovered ? 'block' : 'none'
                                }} />
                                <img src={wechat} alt='' />
                            </div>
                            <div
                                onMouseEnter={handleMouseEnterTictok}
                                onMouseLeave={handleMouseLeaveTictok}
                                className='relative'>
                                <img className='foot_qrcode' src={qrcodeTictok} alt='' style={{
                                    display: tiktokIsHovered ? 'block' : 'none'
                                }} />
                                <img src={tiktok} alt='' />
                            </div>
                            <div
                                onMouseEnter={handleMouseEnterRedbook}
                                onMouseLeave={handleMouseLeaveRedbook}
                                className='relative'>
                                <img className='foot_qrcode l35' src={qrcodeRedbook} alt='' style={{
                                    display: redbookIsHovered ? 'block' : 'none'
                                }} />
                                <img src={redbook} alt='' />
                            </div>
                        </div>
                        <div className='copyright'>
                            <span style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                window.open('https://beian.miit.gov.cn/')
                            }}>湘ICP备2022020554号-1</span>
                            <span style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                window.open('https://dxzhgl.miit.gov.cn/#/home')
                            }}>湘B2-20230548</span>
                            <span>Copyright ©湖南揽星教育咨询有限公司</span>
                        </div>

                        <div className='copyright'>
                            <span>投诉/建议电话：0731-82808000</span>
                            <span>官方邮箱：lanxingjiaoyu123@163.com</span>
                            <span>服务监督电话： 17680645772（领导）</span>
                        </div>
                    </div>
                </div>
            }




            <Modal open={visible} width={width !== 0 && width > 820 ? 800 : '70%'} onCancel={() => {
                setVisibleFalse()
            }}>
                <div className='modal_box'>
                    <img src={width > 820 ? ModalPc : ModalM} alt='' />
                </div>
            </Modal>

            <Modal open={CFAVisible} width={width !== 0 && width > 820 ? 800 : '70%'} onCancel={() => {
                setCFAVisibleFalse()
            }}>
                <div className='modal_box'>
                    {/* <img src={width > 820 ? CFAModalPc : CFAModalM} alt='' /> */}
                    <img src={CFAModalPc} alt='' />
                </div>
            </Modal>

            <Modal open={loginVisible} width={width !== 0 && width > 820 ? 800 : '90%'} onCancel={() => {
                setLoginVisibleFalse()
            }}>
                <Login handleClose={setLoginVisibleFalse} />
            </Modal>
        </div >


    );
}

export default App;
